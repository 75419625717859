import { noop } from '@deltasierra/utilities/object';
import * as React from 'react';
import { useChange } from '../useChange';

export type UseControlledInputProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => void;
    value: string;
};

export type UseControlledInputFunction = (
    callback?: (val: string) => void,
    initialValue?: string,
) => [string, React.Dispatch<React.SetStateAction<string>>, UseControlledInputProps];

/**
 * Controls the value of an input component, returns the props ready to passed in, and calls
 * function whenever value changes.
 *
 * @example
 * const ControlledInput: React.FC<MuiOutlinedInputProps> = props => {
 *   const inputProps = useControlledInput(console.log, 'Initial Value');
 *   return <input {...props} {...inputProps} />;
 * };
 * @param callback - Callback called whenever value changes
 * @param initialValue - Initial value of the input
 * @returns - value and onChange prop to be passed to an input component
 */
export const useControlledInput: UseControlledInputFunction = (callback = noop, initialValue = '') => {
    const [value, setValue] = useChange(initialValue, callback);

    const onChange = React.useCallback(
        (newValue: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
            if (isChangeEventNotString(newValue)) {
                setValue(newValue.target.value);
            } else {
                setValue(newValue);
            }
        },
        [setValue],
    );

    return [value, setValue, { onChange, value }];
};

const isChangeEventNotString = (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
): value is React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => typeof value !== 'string';
