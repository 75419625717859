import { useRef, useEffect } from 'react';
import { noop } from '@deltasierra/utilities/object';

export const useTimeout = (callback: () => void, delay: number | null): void => {
    const timeoutRef = useRef<number>();
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            callbackRef.current();
        }

        if (delay !== null) {
            if (delay === 0) {
                tick();
            } else {
                timeoutRef.current = window.setTimeout(tick, delay);
                return window.clearTimeout(timeoutRef.current);
            }
        }

        return noop;
    }, [delay]);
};
