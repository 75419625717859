import * as React from 'react';
import { ResizeObserver as PolyfilledResizeObserver } from '@juggle/resize-observer';
import { noop } from '@deltasierra/utilities/object';

export type UseResizeObserverHandler<T extends HTMLElement> = (element: T) => void;

export type UseResizeObserverOptions<T extends HTMLElement> = {
    /**
     * Optional React RefObject to attach the resize observer to. This is useful if you want to attach the resize
     * observer to an existing ref/element. The `useResizeObserver` will return a default ref if this option isn't
     * provided.
     */
    ref?: React.RefObject<T>;
    /**
     * Callback to invoke whenever the attached element resizes.
     */
    onResize?: UseResizeObserverHandler<T>;
};

/**
 * Attach a resize observer to an element and invoke the callback whenever a resize event occurs.
 *
 * @example
 * ```tsx
 * const MyComponent = () => {
 *     const ref = useResizeObserver({
 *         onResize: element => console.log(`Offset width: ${element.offsetWidth}`),
 *     });
 *     return <div ref={ref}>Hello, World</div>
 * }
 * ```
 * @param options - Configuration options for the resize observer hook
 * @returns The React RefObject that the resize observer was attached to
 */
export function useResizeObserver<T extends HTMLElement>(
    options: UseResizeObserverOptions<T> = {},
): React.RefObject<T> {
    const defaultRef = React.useRef<T>(null);
    const resizeObserverRef = React.useRef<PolyfilledResizeObserver>();

    const onResizeRef = React.useRef<UseResizeObserverHandler<T> | undefined>(options.onResize);
    onResizeRef.current = options.onResize;

    React.useEffect(() => {
        if (!resizeObserverRef.current) {
            resizeObserverRef.current = new PolyfilledResizeObserver(entries => {
                if (!onResizeRef.current) {
                    return;
                }
                for (const entry of entries) {
                    onResizeRef.current(entry.target as T);
                }
            });
        }
    }, []);

    React.useEffect(() => {
        const element = options.ref?.current || defaultRef.current;
        const resizeObserver = resizeObserverRef.current;
        if (!element || !resizeObserver) {
            return noop;
        }

        resizeObserver.observe(element);
        return () => {
            resizeObserver.unobserve(element);
        };
    }, [options.ref]);
    return options.ref || defaultRef;
}
