import * as React from 'react';
import { noop } from '@deltasierra/utilities/object';
import { useTimeout } from '../useTimeout';

export type UseDelayedFlagProps = {
    falseDelay?: number;
    onFalse: () => void;
    onTrue: () => void;
    trueDelay?: number;
    value: boolean;
};

export const useDelayedFlag = ({
    falseDelay = 0,
    onFalse = noop,
    onTrue = noop,
    trueDelay = 0,
    value,
}: UseDelayedFlagProps): boolean => {
    const [dValue, setDValue] = React.useState(value);
    const onTrueRef = React.useRef(onTrue);
    const onFalseRef = React.useRef(onFalse);
    const trueDelayRef = React.useRef(trueDelay);
    const falseDelayRef = React.useRef(falseDelay);

    React.useEffect(() => {
        onTrueRef.current = onTrue;
        onFalseRef.current = onFalse;
    });

    const setTrue = React.useCallback(() => {
        onTrueRef.current();
        setDValue(true);
    }, []);

    const setFalse = React.useCallback(() => {
        onFalseRef.current();
        setDValue(false);
    }, []);

    useTimeout(setTrue, value && !dValue ? trueDelayRef.current : null);
    useTimeout(setFalse, !value && dValue ? falseDelayRef.current : null);

    return dValue;
};
